import { graphql, useStaticQuery } from 'gatsby'

const useCmsCartSimulation = () => {
  const data = useStaticQuery(
    graphql`
      query CartSimulationQuery {
        cmsCartSimulation {
          sections {
            name
            data
          }
        }
      }
    `
  )

  const simulationPostalCode: string | undefined =
    data?.cmsCartSimulation?.sections?.[0]?.data?.text

  return { simulationPostalCode }
}

export default useCmsCartSimulation
